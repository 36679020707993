export const SET_GAME_START = 'SET_GAME_STARTED';
export const SET_INSTRUCTION_EXPANDED = 'SET_INSTRUCTION_EXPANDED';

export const DECK = {
    FETCH_SUCCESS: 'DECK_FETCH_SUCCESS',
    FETCH_ERROR: 'DECK_FETCH_ERROR',
    RESET: 'RESET'
}

export const CARD = {
    FETCH_SUCCESS: 'CARD_FETCH_SUCCESS',
    FETCH_ERROR: 'CARD_FETCH_ERROR'
}

export const PLAYER_GUESS_CORRECT = 'PLAYER_GUESS_CORRECT';
export const PLAYER_GUESS_WRONG = 'PLAYER_GUESS_WRONG';
export const SET_PLAYER_GUESS = 'SET_PLAYER_GUESS';